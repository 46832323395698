<template>
  <b-card>
    <b-tabs class="tab-primary" v-model="tabIndex">
      <b-tab>
        <template #title>
          <div class="tab-title">
            <feather-icon icon="CornerUpLeftIcon" />
            <span> {{$t('Crear devolución')}}</span>
          </div>
        </template>
        <create></create>
      </b-tab>
      <b-tab lazy>
        <template #title>
          <div class="tab-title">
            <feather-icon icon="FileTextIcon" />
            <span> {{$t('Imprimir')}} </span>
          </div>
        </template>
        <print-return />
      </b-tab>
      <b-tab lazy>
        <template #title>
          <div class="tab-title">
            <feather-icon icon="FileTextIcon" />
            <span> {{$t('Impresos')}} </span>
          </div>
        </template>
        <p class="text-center my-5">Próximamente</p>
      </b-tab>
      <b-tab lazy>
        <template #title>
          <div class="tab-title">
            <feather-icon icon="FileTextIcon" />
            <span> {{$t('Manifiesto')}} </span>
          </div>
        </template>
        <list :sourceList="'return'" />
      </b-tab>
      <b-tab lazy>
        <template #title>
          <div class="tab-title">
            <feather-icon icon="FileTextIcon" />
            <span> {{$t('Retiros')}} </span>
          </div>
        </template>
        <return-pickups-list />
      </b-tab>
    </b-tabs>
  </b-card>
</template>

<script>
import Create from './new-return/Create.vue'
import PrintReturn from '../print-returns/PrintReturn.vue'
import List from '../../manifest/List.vue'
import ReturnPickupsList from '../return-pickups/ReturnPickupsList.vue'

export default {
  name: 'create-return-tabs',
  components: { Create, PrintReturn, List, ReturnPickupsList },
  data() {
    return {
      tabIndex: 0
    }
  }
}
</script>